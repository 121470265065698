import { gql } from 'urql/core';

export default gql`
  mutation createTreatment($input: CreateTreatmentInput!) {
    createTreatment(input: $input) {
      id
      name
      shortName
      treatmentLength
      preMedOptions{
        id
        medication{
          id
            name
            pillAppearance
            pillAppearanceThumb
            medicationType {
              id
              name
            }
            color
            color2
            pillDescription
            instructions
            otherInfo
        }
        daysInterval
        daysRange
        specialInstructions
        dosageType
        length
        givenByNurse
      }
      antiviralTreatment{
        id
        medication{
          id
            name
            pillAppearance
            pillAppearanceThumb
            medicationType {
              id
              name
            }
            color
            color2
            pillDescription
            instructions
            otherInfo
        }
        daysInterval
        daysRange
        specialInstructions
        dosageType
        length
        givenByNurse
      }
      bloodClotPrevention{
        id
        medication{
          id
            name
            pillAppearance
            pillAppearanceThumb
            medicationType {
              id
              name
            }
            color
            color2
            pillDescription
            instructions
            otherInfo
        }
        daysInterval
        daysRange
        specialInstructions
        dosageType
        length
        givenByNurse
      }
      rulesForAllCycles{
        id
        medication{
          id
            name
            pillAppearance
            pillAppearanceThumb
            medicationType {
              id
              name
            }
            color
            color2
            pillDescription
            instructions
            otherInfo
        }
        daysInterval
        daysRange
        specialInstructions
        dosageType
        length
        givenByNurse
      }
      cycles{
        id
        medicationPlans{
          id
          medication{
            id
              name
              pillAppearance
              pillAppearanceThumb
              medicationType {
                id
                name
              }
              color
              color2
              pillDescription
              instructions
              otherInfo
          }
          daysInterval
          daysRange
          specialInstructions
          dosageType
          length
          givenByNurse
        }
        name
      }
    }
  }
`;
